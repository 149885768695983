<template>
  <div v-if="powerCompany" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <b-tabs content-class="" fill>
      <b-tab title="Stammdaten" active>
        <b-row>
          <b-col lg="6" md="6" sm="12">
            <legend>
              <strong>Basisdaten</strong>
            </legend>

            <b-form-group
              horizontal
              :label="$t('company')"
              label-for="company"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input
                v-model="powerCompany.company"
                type="text"
                :placeholder="$t('company')"
              />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('street')"
              label-for="street"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="powerCompany.street" type="text" :placeholder="$t('street')" />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('zip')"
              label-for="zip"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="powerCompany.zip" type="text" :placeholder="$t('zip')" />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('city')"
              label-for="city"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input v-model="powerCompany.city" type="text" :placeholder="$t('city')" />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('cosinusPhi')"
              label-for="cosinusPhi"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input
                v-model="powerCompany.cosinusPhi"
                type="text"
                :placeholder="$t('cosinusPhi')"
              />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('website')"
              label-for="website"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input
                v-model="powerCompany.website"
                type="text"
                :placeholder="$t('website')"
              />
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('operatorOnSiteElectricMeterChange')"
              label-for="operatorOnSiteElectricMeterChange"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <div class="radio">
                <input
                  type="radio"
                  id="yes"
                  value="YES"
                  v-model="powerCompany.operatorOnSiteElectricMeterChange"
                />
                <label for="yes">{{ $t('YES') }}</label>
                <input
                  type="radio"
                  id="no"
                  value="NO"
                  v-model="powerCompany.operatorOnSiteElectricMeterChange"
                />
                <label for="no">{{ $t('NO') }}</label>
              </div>
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('description')"
              label-for="description"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <ckeditor
                :editor="editor"
                v-model="powerCompany.description"
                :config="editorConfig"
                @ready="onEditorReady"
              ></ckeditor>
            </b-form-group>
            <legend>
              <strong>Netzanmeldung / Inbetriebnahmemeldung per E-Mail</strong>
            </legend>

            <b-form-group
              horizontal
              label="Anmeldung per E-Mail"
              label-for="powgridRegistrationPerMailState"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <div class="radio">
                <input
                  type="radio"
                  id="yes"
                  value="YES"
                  v-model="powerCompany.powgridRegistrationPerMailState"
                />
                <label for="yes">{{ $t('YES') }}</label>
                <input
                  type="radio"
                  id="no"
                  value="NO"
                  v-model="powerCompany.powgridRegistrationPerMailState"
                />
                <label for="no">{{ $t('NO') }}</label>
              </div>
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('registrationEmail')"
              label-for="registrationEmail"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input
                v-model="powerCompany.registrationEmail"
                type="text"
                :placeholder="$t('registrationEmail')"
              />
            </b-form-group>

            <b-form-group
              horizontal
              :label="$t('usePowerCompanyFormsState')"
              label-for="usePowerCompanyFormsState"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <div class="radio">
                <input
                  type="radio"
                  id="yes"
                  value="YES"
                  v-model="powerCompany.usePowerCompanyFormsState"
                />
                <label for="yes">{{ $t('YES') }}</label>
                <input
                  type="radio"
                  id="no"
                  value="NO"
                  v-model="powerCompany.usePowerCompanyFormsState"
                />
                <label for="no">{{ $t('NO') }}</label>
              </div>
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('powerCompanyFormsEmbeddedState')"
              label-for="powerCompanyFormsEmbeddedState"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <div class="radio">
                <input
                  type="radio"
                  id="yes"
                  value="YES"
                  v-model="powerCompany.powerCompanyFormsEmbeddedState"
                />
                <label for="yes">{{ $t('YES') }}</label>
                <input
                  type="radio"
                  id="no"
                  value="NO"
                  v-model="powerCompany.powerCompanyFormsEmbeddedState"
                />
                <label for="no">{{ $t('NO') }}</label>
              </div>
            </b-form-group>

            <legend>
              <strong>Netzanmeldung / Inbetriebnahmemeldung via Online-Portal</strong>
            </legend>

            <b-form-group
              horizontal
              label="Anmeldung über Portal"
              label-for="powgridRegistrationOnlineState"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <div class="radio">
                <input
                  type="radio"
                  id="yes"
                  value="YES"
                  v-model="powerCompany.powgridRegistrationOnlineState"
                />
                <label for="yes">{{ $t('YES') }}</label>
                <input
                  type="radio"
                  id="no"
                  value="NO"
                  v-model="powerCompany.powgridRegistrationOnlineState"
                />
                <label for="no">{{ $t('NO') }}</label>
              </div>
            </b-form-group>
            <b-form-group
              horizontal
              :label="$t('registrationUrl')"
              label-for="registrationUrl"
              label-class="text-md-right"
              :label-cols="4"
              breakpoint="md"
            >
              <b-form-input
                v-model="powerCompany.registrationUrl"
                type="text"
                :placeholder="'https://'"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12">
            <legend>
              <strong>Ansprechpartner</strong>
              <div class="button-right">
                <button class="btn btn-sm btn-success right" @click.prevent="onAddContact">
                  <i class="fa fa-plus" />
                </button>
              </div>
            </legend>

            <div
              class="powerCompanyContacts"
              v-for="(contact, index) in powerCompany.contacts"
              :key="index"
            >
              <b-form-group
                horizontal
                :label="$t('firstname')"
                label-for="firstname"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <b-form-input
                  v-model="contact.firstname"
                  type="text"
                  :placeholder="$t('firstname')"
                />
              </b-form-group>
              <b-form-group
                horizontal
                :label="$t('lastname')"
                label-for="lastname"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <b-form-input
                  v-model="contact.lastname"
                  type="text"
                  :placeholder="$t('lastname')"
                />
              </b-form-group>
              <b-form-group
                horizontal
                :label="$t('position')"
                label-for="position"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <b-form-input
                  v-model="contact.position"
                  type="text"
                  :placeholder="$t('position')"
                />
              </b-form-group>
              <b-form-group
                horizontal
                :label="$t('phone')"
                label-for="phone"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <b-form-input v-model="contact.phone" type="text" :placeholder="$t('phone')" />
              </b-form-group>
              <b-form-group
                horizontal
                :label="$t('mobile')"
                label-for="mobile"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <b-form-input v-model="contact.mobile" type="text" :placeholder="$t('mobile')" />
              </b-form-group>
              <b-form-group
                horizontal
                :label="$t('email')"
                label-for="email"
                label-class="text-md-right"
                :label-cols="4"
                breakpoint="md"
              >
                <b-form-input v-model="contact.email" type="text" :placeholder="$t('email')" />
              </b-form-group>
              <i class="fa fa-trash-o trash-contact" @click="onTrashContact(index)" />
              <hr />
            </div>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="Dokumente">
        <b-row>
          <b-col lg="4" md="4" sm="12">
            <legend>
              <strong>{{ $t('documentAttachments') }}</strong>
            </legend>

            <DropzoneUpload
              v-if="!isNew"
              :complete="isAttachmentUploadComplete"
              :parentId="powerCompany._id"
              parentType="documentAttachments"
              :uploadCallback="uploadPowerCompanyAttachment"
              v-on:dropzone-upload:reset="isAttachmentUploadComplete = false"
            />

            <AttachmentList
              :attachments="powerCompany.documentAttachments"
              attachmentType="documentAttachments"
              v-on:attachment-list:delete="onDeletePowerCompanyAttachment"
            ></AttachmentList>
          </b-col>
          <b-col lg="8" md="8" sm="12">
            <legend>
              <strong>Netzanmeldung</strong>
            </legend>

            <b-row class="mt-4">
              <b-col lg="12" md="12" sm="12">
                <b-form-group
                  horizontal
                  :label="$t('additional_documents')"
                  label-for="netRegistrationLinkedDocuments"
                  label-class="text-md-right"
                  :label-cols="4"
                  breakpoint="md"
                >
                  <multiselect
                    v-model="powerCompany.netRegistrationLinkedDocuments"
                    id="netRegistrationLinkedDocuments"
                    :options="getNetRegistrationDocuments"
                    track-by="id"
                    label="name"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :multiple="true"
                    :taggable="true"
                    :placeholder="$t('select_documents')"
                    :show-labels="false"
                  >
                  </multiselect>
                </b-form-group>
                <b-form-group
                  horizontal
                  :label="$t('netRegistrationDocumentsApprovedState')"
                  label-for="netRegistrationDocumentsApprovedState"
                  label-class="text-md-right"
                  :label-cols="4"
                  breakpoint="md"
                >
                  <multiselect
                    :placeholder="$t('netRegistrationDocumentsApprovedState')"
                    v-model="powerCompany.netRegistrationDocumentsApprovedState"
                    :options="optionsYesNo"
                    :show-labels="false"
                    :allow-empty="false"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><b-badge :variant="yesNoColor(option)">{{ $t(option) }}</b-badge></template
                    >
                    <template slot="option" slot-scope="{ option }">
                      <b-badge :variant="yesNoColor(option)">
                        {{ $t(option) }}
                      </b-badge></template
                    >
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <legend>
              <strong>Inbetriebnahmemeldung</strong>
            </legend>
            <b-row class="mt-4">
              <b-col lg="12" md="12" sm="12">
                <b-form-group
                  horizontal
                  :label="$t('additional_documents')"
                  label-for="operationRegistrationLinkedDocuments"
                  label-class="text-md-right"
                  :label-cols="4"
                  breakpoint="md"
                >
                  <multiselect
                    v-model="powerCompany.operationRegistrationLinkedDocuments"
                    id="operationRegistrationLinkedDocuments"
                    :options="getOperationRegistrationDocuments"
                    track-by="id"
                    label="name"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :multiple="true"
                    :taggable="true"
                    :placeholder="$t('select_documents')"
                    :show-labels="false"
                  >
                  </multiselect>
                </b-form-group>
                <b-form-group
                  horizontal
                  :label="$t('operationRegistrationDocumentsApprovedState')"
                  label-for="operationRegistrationDocumentsApprovedState"
                  label-class="text-md-right"
                  :label-cols="4"
                  breakpoint="md"
                >
                  <multiselect
                    :placeholder="$t('operationRegistrationDocumentsApprovedState')"
                    v-model="powerCompany.operationRegistrationDocumentsApprovedState"
                    :options="optionsYesNo"
                    :show-labels="false"
                    :allow-empty="false"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      ><b-badge :variant="yesNoColor(option)">{{ $t(option) }}</b-badge></template
                    >
                    <template slot="option" slot-scope="{ option }">
                      <b-badge :variant="yesNoColor(option)">
                        {{ $t(option) }}
                      </b-badge></template
                    >
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>
            <legend>
              <strong>{{ $t('notes') }}</strong>
            </legend>
            <b-row class="mt-4">
              <b-col lg="12" md="12" sm="12">
                <ckeditor
                  :editor="editor"
                  v-model="powerCompany.documentsDescription"
                  :config="editorConfig"
                  @ready="onEditorReady"
                ></ckeditor>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-tab>

      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showDeleteButton="buttons.showDeleteButton"
            :model="powerCompany"
            modelEditRoute="PowerCompanyEditPage"
            modelListRoute="PowerCompaniesPage"
            modelRouteParamName="powerCompanyNumber"
            :updateCallback="updatePowerCompany"
            :createCallback="createPowerCompany"
            :deleteCallback="deletePowerCompany"
            :fetchCallback="fetchPowerCompanies"
          />
        </b-col>
      </b-row>
    </b-tabs>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import { yesNo } from '@/helpers/enums';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentList from '@/components/AttachmentList';
import Multiselect from 'vue-multiselect';
import { getYesNoStateColor } from '@/helpers/colors';
import ButtonBar from '@/components/ButtonBar.vue';

export default {
  name: 'PowerCompanyEditPage',
  components: {
    Breadcrumbs,
    ckeditor: CKEditor.component,
    DropzoneUpload,
    AttachmentList,
    Multiselect,
    ButtonBar,
  },
  props: {
    powerCompanyNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isAttachmentUploadComplete: false,
      number: null,
      user: {},
      buttonsDisabled: false,
      editor: InlineEditor,
      editorConfig: {},
    };
  },
  computed: {
    ...mapGetters(['getPowerCompany', 'getPowerCompanyByNumber', 'getDocuments']),
    buttons() {
      return {
        showDeleteButton: this.powerCompany && this.powerCompany.number !== '_new' ? true : false,
      };
    },
    optionsYesNo() {
      return yesNo();
    },
    powerCompany() {
      return this.getPowerCompanyByNumber(this.number);
    },
    isNew() {
      return this.powerCompany._id === '_new';
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: 'EVU', route: { name: 'PowerCompaniesPage' } },
        { name: this.powerCompany.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
    getOperationRegistrationDocuments() {
      const documents = [];
      this.getDocuments.map((document) => {
        if (document.type === 'OPERATION_REGISTRATION') {
          documents.push(document);
        }
      });
      return documents;
    },
    getNetRegistrationDocuments() {
      const documents = [];
      this.getDocuments.map((document) => {
        if (document.type === 'NET_REGISTRATION') {
          documents.push(document);
        }
      });
      return documents;
    },
  },
  methods: {
    ...mapActions([
      'initPowerCompany',
      'fetchPowerCompanies',
      'fetchPowerCompanyByNumber',
      'updatePowerCompany',
      'createPowerCompany',
      'deletePowerCompany',
      'addEmptyPowerCompanyContact',
      'removePowerCompanyContact',
      'deletePowerCompanyAttachment',
      'uploadPowerCompanyAttachment',
      'fetchDocuments',
    ]),
    onEditorReady(event) {},
    onAddContact() {
      this.addEmptyPowerCompanyContact(this.powerCompany);
    },
    onTrashContact(index) {
      this.removePowerCompanyContact({
        updatedPowerCompany: this.powerCompany,
        index: index,
      });
    },
    onDeletePowerCompanyAttachment(event) {
      this.deletePowerCompanyAttachment({
        powerCompanyId: this.powerCompany._id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },
    yesNoColor(state) {
      return getYesNoStateColor(state);
    },
  },
  async mounted() {
    if (!this.powerCompany && this.powerCompanyNumber) {
      this.number = this.powerCompanyNumber;
      await this.fetchPowerCompanyByNumber(this.number);
    } else {
      this.initPowerCompany();
      this.number = '_new';
    }
    await this.fetchDocuments();

    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadPowerCompanyAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
  },
  /**
   * if only parameters of the route have changed the component will be reused
   * we have to update all the data before the route updates
   * @param to
   * @param from
   * @param next
   */
  async beforeRouteUpdate(to, from, next) {
    // console.log('Route params', to.params);
    // console.log('Current powerCompany number', this.powerCompanyNumber);
    if (to.params.powerCompanyNumber && to.params.powerCompanyNumber !== this.powerCompanyNumber) {
      this.$store.commit('SET_POWER_COMPANY_DEFAULT_STATE');
      this.number = to.params.powerCompanyNumber;
      await this.fetchPowerCompanyByNumber(this.number);
      await this.setup();
      next();
    } else {
      next(false);
    }
  },
};
</script>

<style scoped lang="scss">
.powerCompanyContacts {
  text-align: right;
}
.trash-contact {
  cursor: pointer;
}
legend {
  position: relative;
}
.button-right {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 2px;
}
.ck-content {
  min-height: 80px;
}
</style>
